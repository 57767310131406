import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth, AuthProvider } from './context/AuthContext';
import { ThemeProvider } from './context/ThemeContext';
import { DateFilterProvider } from './context/DateFilterContext';
import { WalletProvider } from './context/WalletContext';
import { TransactionProvider } from './context/TransactionContext';
import { CategoryProvider } from './context/CategoryContext';
import { LabelProvider } from './context/LabelContext';
import { BudgetProvider } from './context/BudgetContext';
import { CreditCardProvider } from './context/CreditCardContext';
import { SubscriptionProvider } from './context/SubscriptionContext';
import { AdminProvider } from './context/AdminContext';
import { NotificationProvider } from './context/NotificationContext';
import { LanguageProvider } from './context/LanguageContext';
import { CurrencyProvider } from './context/CurrencyContext';
import PrivateRoute from './components/PrivateRoute';
import { LoadingSpinner } from './components/LoadingSpinner';

// Lazy load pages with error boundaries
const lazyLoad = (importFn: () => Promise<any>) => {
  const LazyComponent = React.lazy(importFn);
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <LazyComponent />
    </Suspense>
  );
};

// Lazy load pages
const Dashboard = () => lazyLoad(() => import('./components/Dashboard'));
const TransactionsPage = () => lazyLoad(() => import('./pages/TransactionsPage'));
const WalletsPage = () => lazyLoad(() => import('./pages/WalletsPage'));
const WalletDetailsPage = () => lazyLoad(() => import('./pages/WalletDetailsPage'));
const CategoriesPage = () => lazyLoad(() => import('./pages/CategoriesPage'));
const LabelsPage = () => lazyLoad(() => import('./pages/LabelsPage'));
const BudgetsPage = () => lazyLoad(() => import('./pages/BudgetsPage'));
const BudgetDetailsPage = () => lazyLoad(() => import('./pages/BudgetDetailsPage'));
const ReportsPage = () => lazyLoad(() => import('./pages/ReportsPage'));
const InviteMembersPage = () => lazyLoad(() => import('./pages/InviteMembersPage'));
const LoginPage = () => lazyLoad(() => import('./pages/LoginPage'));
const RegisterPage = () => lazyLoad(() => import('./pages/RegisterPage'));
const VerifyEmailPage = () => lazyLoad(() => import('./pages/VerifyEmailPage'));
const AccountPage = () => lazyLoad(() => import('./pages/AccountPage'));
const CreditCardsPage = () => lazyLoad(() => import('./pages/CreditCardsPage'));
const SubscriptionsPage = () => lazyLoad(() => import('./pages/SubscriptionsPage'));
const AcceptInvitationPage = () => lazyLoad(() => import('./pages/AcceptInvitationPage'));

// Lazy load admin pages
const AdminDashboard = () => lazyLoad(() => import('./pages/admin/AdminDashboard'));
const AdminUsers = () => lazyLoad(() => import('./pages/admin/AdminUsers'));
const AdminSettings = () => lazyLoad(() => import('./pages/admin/AdminSettings'));
const AdminSubscriptions = () => lazyLoad(() => import('./pages/admin/AdminSubscriptions'));
const AdminCategories = () => lazyLoad(() => import('./pages/admin/AdminCategories'));
const AdminLabels = () => lazyLoad(() => import('./pages/admin/AdminLabels'));
const AdminEmails = () => lazyLoad(() => import('./pages/admin/AdminEmails'));
const AdminLanguages = () => lazyLoad(() => import('./pages/admin/AdminLanguages'));
const AdminPlatforms = () => lazyLoad(() => import('./pages/admin/AdminPlatforms'));

// Lazy load layouts
const AdminLayout = () => lazyLoad(() => import('./components/admin/AdminLayout'));
const MainLayout = () => lazyLoad(() => import('./components/layouts/MainLayout'));

const AppContent = () => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <SubscriptionProvider>
      <CreditCardProvider>
        <BudgetProvider>
          <LabelProvider>
            <CategoryProvider>
              <WalletProvider>
                <TransactionProvider>
                  <DateFilterProvider>
                    <ThemeProvider>
                      <AdminProvider>
                        <Routes>
                          {/* Public Routes */}
                          <Route path="/login" element={<LoginPage />} />
                          <Route path="/register" element={<RegisterPage />} />
                          <Route path="/verify-email" element={<VerifyEmailPage />} />
                          <Route path="/accept-invitation" element={<AcceptInvitationPage />} />

                          {/* Protected Routes */}
                          <Route element={<MainLayout />}>
                            <Route path="/" element={
                              <PrivateRoute>
                                <Dashboard />
                              </PrivateRoute>
                            } />
                            <Route path="/transactions" element={
                              <PrivateRoute>
                                <TransactionsPage />
                              </PrivateRoute>
                            } />
                            <Route path="/wallets" element={
                              <PrivateRoute>
                                <WalletsPage />
                              </PrivateRoute>
                            } />
                            <Route path="/wallets/:id" element={
                              <PrivateRoute>
                                <WalletDetailsPage />
                              </PrivateRoute>
                            } />
                            <Route path="/budgets" element={
                              <PrivateRoute>
                                <BudgetsPage />
                              </PrivateRoute>
                            } />
                            <Route path="/budgets/:budgetId" element={
                              <PrivateRoute>
                                <BudgetDetailsPage />
                              </PrivateRoute>
                            } />
                            <Route path="/reports" element={
                              <PrivateRoute>
                                <ReportsPage />
                              </PrivateRoute>
                            } />
                            <Route path="/credit-cards" element={
                              <PrivateRoute>
                                <CreditCardsPage />
                              </PrivateRoute>
                            } />
                            <Route path="/subscriptions" element={
                              <PrivateRoute>
                                <SubscriptionsPage />
                              </PrivateRoute>
                            } />
                            <Route path="/settings/categories" element={
                              <PrivateRoute>
                                <CategoriesPage />
                              </PrivateRoute>
                            } />
                            <Route path="/settings/labels" element={
                              <PrivateRoute>
                                <LabelsPage />
                              </PrivateRoute>
                            } />
                            <Route path="/settings/invite" element={
                              <PrivateRoute>
                                <InviteMembersPage />
                              </PrivateRoute>
                            } />
                            <Route path="/settings/account" element={
                              <PrivateRoute>
                                <AccountPage />
                              </PrivateRoute>
                            } />
                          </Route>

                          {/* Admin Routes */}
                          {(user?.role === 'admin' || user?.role === 'superadmin') && (
                            <Route path="/admin" element={
                              <PrivateRoute>
                                <AdminLayout />
                              </PrivateRoute>
                            }>
                              <Route index element={<AdminDashboard />} />
                              <Route path="users" element={<AdminUsers />} />
                              <Route path="settings" element={<AdminSettings />} />
                              <Route path="subscriptions" element={<AdminSubscriptions />} />
                              <Route path="platforms" element={<AdminPlatforms />} />
                              <Route path="categories" element={<AdminCategories />} />
                              <Route path="labels" element={<AdminLabels />} />
                              <Route path="emails" element={<AdminEmails />} />
                              <Route path="languages" element={<AdminLanguages />} />
                            </Route>
                          )}

                          {/* Fallback Route */}
                          <Route path="*" element={<Navigate to={user ? '/' : '/login'} replace />} />
                        </Routes>
                      </AdminProvider>
                    </ThemeProvider>
                  </DateFilterProvider>
                </TransactionProvider>
              </WalletProvider>
            </CategoryProvider>
          </LabelProvider>
        </BudgetProvider>
      </CreditCardProvider>
    </SubscriptionProvider>
  );
};

const App = () => {
  return (
    <Router>
      <NotificationProvider>
        <AuthProvider>
          <LanguageProvider>
            <CurrencyProvider>
              <AppContent />
            </CurrencyProvider>
          </LanguageProvider>
        </AuthProvider>
      </NotificationProvider>
    </Router>
  );
};

export default App;
